$(document).foundation();

// ---------------------------------
// スマホナビ
// ---------------------------------
(function($){
	$('.hamburger').on('click',function(){
		$('.hamburger').toggleClass('is-active');
	});

	var $overRayMenu = $('.js-spOverrayNav');
	var $toggleBtn = $('.hamburger');
	var isActive = false;

	function toggleView(){
		if(isActive === true){
			$toggleBtn.addClass('is-active');
			$overRayMenu.addClass('is-active');
		} else {
			$toggleBtn.removeClass('is-active');
			$overRayMenu.removeClass('is-active');
		}
	}
	// ボタンのON/OFF & カスタムイベント送信
	$toggleBtn.on('click',function(){
		isActive = !isActive;
		toggleView();
	});
	// ナビクリックでオーバーレイ閉じる
	$overRayMenu.find('a[href^="#"]').on('click',function(){
		isActive = false;
		toggleView();
	});

})(jQuery);


// ---------------------------------
// ハッシュスクロール
// ---------------------------------
$(function() {
  // スクロールのオフセット値
  var offsetY = -10;
  // スクロールにかかる時間
  var time = 700;

  // ページ内リンクのみを取得
  $('a[href^="#"]').click(function() {
    // 移動先となる要素を取得
    var target = $(this.hash);
    if (!target.length) { return ; }
    // 移動先となる値
		var targetY = target.offset().top+offsetY;
    // スクロールアニメーション
    $('html,body').animate({scrollTop: targetY}, time, 'swing');
    // ハッシュ書き換えとく
    window.history.pushState(null, null, this.hash);
    // デフォルトの処理はキャンセル
    return false;
  });
});

